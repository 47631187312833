(function(w,d){
    w.poll_config.forEach(function(o) {
        // Check if user has voted on this poll
        var pollVoted = localStorage.getItem('poll_' + o.asset_id);
        
        // User has voted
        if(pollVoted) {
            // Popluate vote and show results
            d.getElementById('poll-'+ o.id +'-your-vote').innerHTML = pollVoted;
            d.getElementById('poll-'+ o.id +'-your-vote-container').classList.remove('hide');
            pollResults();
        }
        
        // Poll vote submission
        d.getElementById('poll-'+ o.id +'-form').addEventListener('submit', function(e){
            e.preventDefault();
            var pollAnswer = d.querySelector('#poll-'+ o.id +'-form input[name=answer]:checked').value;
            if(!pollAnswer) return false;
            var pollAnswerNice = d.querySelector('#poll-'+ o.id +'-form input[name=answer]:checked').getAttribute('title');
            
            // DMP data
            if (typeof dmpAction == 'function') {
                var pollDmpData = 'Poll : '+ o.title +' : ' + pollAnswerNice;
                dmpAction(pollDmpData); // Passes off into predefined dmp functions
            }
            
            // Setup vote request
            var xhr = new XMLHttpRequest(),
                csrfToken = Cookies.get('tncms_csrf_token'),
                dataString = 'answer=' + pollAnswer;

            if(csrfToken){
                dataString += "&tncms_csrf_token=" + csrfToken;
            }

            xhr.open('POST', o.url + '?format=json&action=poll:vote');
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onreadystatechange = function() {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Log vote
                        localStorage.setItem('poll_'+ o.asset_id, pollAnswerNice);
                        pollVoted = pollAnswerNice;
                                    
                        // Chart and show results
                        pollResults(false, true);
                        
                        // Popluate and show user vote
                        d.getElementById('poll-'+ o.id +'-your-vote').innerHTML = pollVoted;
                        d.getElementById('poll-'+ o.id +'-your-vote-container').classList.remove('hide');
                    }
                    else {
                        console.log('A poll error occurred: ' + xhr.status);
                    }
                }
            };
            // Send vote
            xhr.send(encodeURI(dataString));
        });
        
        // Fetch poll results and display chart
        function pollResults(bBackBtn, bRunChart){
            var poll = d.getElementById('poll-' + o.id);
            if (poll.getAttribute('data-charted') == 'false' || bRunChart == true) {
                fetch(o.results).then(
                    function(response) {
                        if (response.status !== 200) {
                            console.log('Poll '+ o.asset_id + ' Status Code: ' + response.status);
                            return;
                        }
                        // Examine the text in the response
                        response.json().then(function(data) {
                            // Create pie or bar chart
                            var chart;
                            if (o.chart == 'pie') {
                                chart = pieChart(data.results);
                            } else {
                                chart = barChart(data.results);
                            }

                            // Insert chart
                            d.getElementById('poll-'+ o.id +'-results').innerHTML = chart;
                            
                            // Tag poll as charted
                            poll.setAttribute('data-charted', 'true');
                        });
                    }
                )
                .catch(function(err) {
                    console.log('Fetch Error '+ o.asset_id + ': ', err);
                });
            }
            // Show results panel
            showResults(bBackBtn);
        }
        
        // Show results area
        function showResults(bBack){
            // Hide voting 
            d.getElementById('poll-'+ o.id +'-container').classList.add('hide');
            
            // Show chart
            d.getElementById('poll-'+ o.id +'-results').classList.remove('hide');
            
            // Back button
            if (bBack) {
                d.getElementById('poll-'+ o.id +'-back-vote').classList.remove('hide');
            }
        }
        
        // Results button clicked 
        d.getElementById('poll-'+ o.id +'-get-results').addEventListener('click', function(e) {
            e.preventDefault();
            pollResults(true);
            return false;
        });
        
        // Back button clicked
        d.getElementById('poll-'+ o.id +'-back-btn').addEventListener('click', function(e) {
            e.preventDefault();
            d.getElementById('poll-'+ o.id +'-back-vote').classList.add('hide');
            d.getElementById('poll-'+ o.id +'-results').classList.add('hide');
            d.getElementById('poll-'+ o.id +'-container').classList.remove('hide');
        });
        
        // Get vote total
        function totalVotes(results) {
            var total = 0;
            for (var key in results) {
                if (results.hasOwnProperty(key)) {
                    var item = results[key],
                        itemVotes = item.votes;
                    if (itemVotes === 0 && item.text === pollVoted){
                        itemVotes = 1;
                    } 
                    total += itemVotes;
                }
            }
            return total;
        }
        
        // Pie chart
        function pieChart(results) {
            // Common vars
            var total = totalVotes(results),
                cumulativeVotes = 0,
                lineCoords = '100,0',
                htmlLedger = '<div class="tnt-chart-ledger">',
                htmlChart = '<div class="tnt-chart"><svg class="tnt-chart-pie" viewBox="-100 -100 200 200" aria-labelledby="'+ o.title +'"><g transform="rotate(-90)">';
            
            // Process results and create chart
            for (var key in results) {
                if (results.hasOwnProperty(key)) {
                    var item = results[key],
                        iNumVotes = item.votes;
                    
                    // Force count for first voter
                    if(iNumVotes === 0 && item.text === pollVoted){
                        iNumVotes = 1;
                    }
                    
                    // Percent val for legend
                    var percentVal = (iNumVotes / total * 100).toFixed(1);
                    
                    if (iNumVotes != 0){
                        if (cumulativeVotes >= total) {
                           // Finished with chart return to start
                           lineCoords = '100,0';
                        }; 
                        
                        // Add current vote count to cumulative
                        cumulativeVotes += iNumVotes;
                        
                        // Calculate pie chart coordinates
                        var a = 2 * Math.PI * cumulativeVotes/total,
                            x = Math.cos(a) * 100,
                            y = Math.sin(a) * 100,
                            largeArc = (total/iNumVotes <= 2) ? 1 : 0,
                            voteText = (iNumVotes != 1 ? 'votes' : 'vote');
                        
                        // Add chart piece
                        htmlChart += '<path d="M0,0 L'+ lineCoords +' A100,100 0 '+ largeArc +',1 '+ x + ',' + y +' Z" fill="'+ item.color +'"><title>' + iNumVotes + ' ' + voteText + '</title></path>';
                        
                        // Add ledger item
                        htmlLedger += '<p class="tnt-ledger-item" title="'+ iNumVotes +' '+ voteText +'"><span style="color:'+ item.color +'"><span class="fas tnt-circle"></span></span> <strong>'+ percentVal + '%</strong> ' + item.text + '</p>';
                        
                        // Set up next line cooredinates
                        lineCoords = x + ',' + y;
                    }
                }
            }
            
            // If no chart has been created return message
            if (cumulativeVotes <= 0) return '<div class="alert alert-warning">No votes have been submitted.</div>';
            
            // Add ledger and complete graph
            htmlChart = htmlChart + '</g></svg>' + htmlLedger + '</div></div>';
            
            return htmlChart;
        }
        
        // Bar chart
        function barChart(results) {
            // Common vars
            var total = totalVotes(results),
                cumulativeVotes = 0,
                barStack = 0,
                barHeight = 20,
                htmlChart = '';
            
            // Process results and create chart
            for (var key in results) {
                if (results.hasOwnProperty(key)) {
                    var item = results[key],
                        iNumVotes = item.votes,
                        label = 'option-' + o.id + '-' + key;
                    
                    // Force count for first voter
                    if(iNumVotes === 0 && item.text === pollVoted){
                        iNumVotes = 1;
                    }
                    
                    if (iNumVotes != 0){
                        var percentVal = (iNumVotes / total * 100).toFixed(1);
                            barWidth = percentVal/2;

                        // Add chart piece
                        htmlChart += '<svg class="tnt-chart-bar" aria-labelledby="'+ label +'" role="img"><g class="bar"><rect width="'+ barWidth +'%" height="20" y="0" style="fill: '+ item.color +'"></rect><text class="tnt-chart-percent" x="'+ (barWidth + 1) +'%" y="10" dy=".35em">'+ percentVal +'%</text><text class="tnt-chart-count" x="'+ (barWidth + 1) +'%" y="10" dy=".35em">'+ item.votes +'</text></g></svg><p id="'+ label +'" class="tnt-chart-bar-text">' + item.text + '</p>'; 
                        
                        // Add current vote count to cumulative
                        cumulativeVotes += iNumVotes;
                    }
                }
            }
            
            // If no chart has been placed return message
            if (cumulativeVotes <= 0) return '<div class="alert alert-warning">No votes have been submitted.</div>';
            return htmlChart;
        }
    });
})(window, document);
